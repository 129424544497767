
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'TeachingAids',
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
    teachingAidsTrainingType: {
      type: Array,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: '',
    },
  },
  data () {
    return {
      teachingAids: this.value,
    }
  },
  watch: {
    value: {
      handler (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.teachingAids = newVal
        }
      },
      immediate: true,
    },
    teachingAids: {
      handler (newVal) {
        this.$emit('input', newVal)
      },
    },
  },
})
